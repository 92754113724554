// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nOaZO5scV", "CjSSYXbUi", "oamHMFqxp", "ee5uOBXLX", "pTEahyB4X", "mnESKkffe", "DP6UMFn6J", "iX5OEXzBX", "soHPSFdDz"];

const serializationHash = "framer-DAq9C"

const variantClassNames = {CjSSYXbUi: "framer-v-rxiimi", DP6UMFn6J: "framer-v-1dey6pq", ee5uOBXLX: "framer-v-1me4gdw", iX5OEXzBX: "framer-v-4bvvmt", mnESKkffe: "framer-v-1lzzase", nOaZO5scV: "framer-v-va2g4b", oamHMFqxp: "framer-v-1oxp0fm", pTEahyB4X: "framer-v-1828rh", soHPSFdDz: "framer-v-1jzqi9h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, ee5uOBXLX: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "mnESKkffe", "Image 16:9": "ee5uOBXLX", "Image 3:2": "oamHMFqxp", "Image 4:3": "CjSSYXbUi", "Image 4:5": "pTEahyB4X", "Variant 7": "DP6UMFn6J", "Variant 8": "iX5OEXzBX", "Variant 9": "soHPSFdDz", Default: "nOaZO5scV"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nOaZO5scV", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "nOaZO5scV", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-va2g4b", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"nOaZO5scV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({CjSSYXbUi: {"data-framer-name": "Image 4:3"}, DP6UMFn6J: {"data-framer-name": "Variant 7"}, ee5uOBXLX: {"data-framer-name": "Image 16:9"}, iX5OEXzBX: {"data-framer-name": "Variant 8"}, mnESKkffe: {"data-framer-name": "Image 1:1"}, oamHMFqxp: {"data-framer-name": "Image 3:2"}, pTEahyB4X: {"data-framer-name": "Image 4:5"}, soHPSFdDz: {"data-framer-name": "Variant 9"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-DAq9C[data-border=\"true\"]::after, .framer-DAq9C [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DAq9C.framer-a0k3yp, .framer-DAq9C .framer-a0k3yp { display: block; }", ".framer-DAq9C.framer-va2g4b { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-DAq9C.framer-v-rxiimi.framer-va2g4b { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-DAq9C.framer-v-1oxp0fm.framer-va2g4b { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-DAq9C.framer-v-1me4gdw.framer-va2g4b { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-DAq9C.framer-v-1828rh.framer-va2g4b { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-DAq9C.framer-v-1lzzase.framer-va2g4b { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-DAq9C.framer-v-1dey6pq.framer-va2g4b { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-DAq9C.framer-v-4bvvmt.framer-va2g4b, .framer-DAq9C.framer-v-1jzqi9h.framer-va2g4b { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CjSSYXbUi":{"layout":["fixed","fixed"]},"oamHMFqxp":{"layout":["fixed","fixed"]},"ee5uOBXLX":{"layout":["fixed","fixed"]},"pTEahyB4X":{"layout":["fixed","fixed"]},"mnESKkffe":{"layout":["fixed","fixed"]},"DP6UMFn6J":{"layout":["fixed","fixed"]},"iX5OEXzBX":{"layout":["fixed","fixed"]},"soHPSFdDz":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerY4sMgPAlY: React.ComponentType<Props> = withCSS(Component, css, "framer-DAq9C") as typeof Component;
export default FramerY4sMgPAlY;

FramerY4sMgPAlY.displayName = "Image / Aspect Ratio Copy 2";

FramerY4sMgPAlY.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerY4sMgPAlY, {variant: {options: ["nOaZO5scV", "CjSSYXbUi", "oamHMFqxp", "ee5uOBXLX", "pTEahyB4X", "mnESKkffe", "DP6UMFn6J", "iX5OEXzBX", "soHPSFdDz"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerY4sMgPAlY, [])